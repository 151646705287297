<template>
  <div class="relative imgCard mt-6" :style="imageStyle">
    <!--Hidden input for file upload -->
    <input
      type="file"
      class="hidden"
      id="fileInput"
      ref="fileInput"
      :disabled="disabled"
      accept="image/x-png,image/gif,image/jpeg"
      @change="handleFileInputChange"
      multiple
    />
    <img
      :src="imageUploaded || photo"
      alt="user-photo"
      v-if="photo"
      class="h-full w-full"
    />
    <div
      class="text-blueCrayola h-full w-full flex justify-center items-center text-center text-xl font-semibold py-1"
      v-else
    >
      {{ $getInitials(`${name}`) }}
    </div>

    <div
      class=" bg-white cursor-pointer user-profile-photo--opacity
         absolute text-flame"
      @click="uploadPhoto"
      :style="iconStyle"
      style="border: 2px solid #eee;"
    >
      <Icon icon-name="camera" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImageUpload",
  props: {
    photo: {
      type: String,
      default: ""
    },
    disabled:{
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: ""
    },
    imageStyle: {
      type: String,
      default: "height:60px; width:60px"
    },
    iconStyle: {
      type: String,
      default: "left: 70%;top: 71.67%;bottom: 3.33%;border-radius: 100%;"
    }
  },
  data() {
    return {
      file: "",
      url: process.env.VUE_APP_IMAGE_UPLOAD,
      uploading: false,
      imageUploaded: ""
    };
  },
  watch: {
    file() {
      this.file && this.uploadPhoto();
    }
  },
  methods: {
    handleFileInputChange() {
      const [files] = this.$refs.fileInput.files;
      this.file = files;
    },
    async uploadPhoto() {
      if (!this.file) {
        this.$refs.fileInput.click();
      } else {
        try {
          this.uploading = true;
          // eslint-disable-next-line no-console
          console.log(this.uploading, "upload starting");
          const formData = new FormData();
          formData.append("file", this.file);
          const { data } = await axios.post(this.url, formData);
          this.imageUploaded = data.fileUrl;
          this.$emit("file", data.fileUrl);
          this.file = "";
          this.uploading = false;
        } catch (err) {
          throw new Error(err);
        }
      }
    }
  }
};
</script>

<style scoped>
.user-profile-photo {
  left: 70%;
  top: 71.67%;
  bottom: 3.33%;
  border-radius: 100%;
}
.imgCard {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
  border-radius: 5px;
}

.user-profile-photo--opacity {
  opacity: 0.9;
}
</style>
